<template>
  <div class="px-4 py-2 stop">
    <div class="py-4 font-bold text-black border-b border-gray-300 title">
      {{ title }}
    </div>
    <div class="pt-2 content_item">
      <div class="flex items-center justify-between">
        <div class="flex items-center item">
          <div>订单编号：</div>
          <div class="py-2 pr-4 input_box">
            <a-input size="small" placeholder="请输入" v-model="order" />
          </div>
          <a-button size="small" type="primary">查询</a-button>
        </div>
      </div>
      <div class="pt-4">
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :scroll="{ x: 1000 }"
          :pagination="pagination"
        >
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { collectList } from "@/api/collect";
export default {
  data() {
    return {
      title: "",
      id: "",
      order: "",
      dataSource: [
        // {
        //   key: "0",
        //   number: "18746124516451986",
        //   date: "2020-12-12 14:30~15：30",
        //   model: "男上衣",
        //   type: "上衣",
        //   encipher: "B00101",
        //   size: "S",
        //   status: "采集中",
        // },
        // {
        //   key: "1",
        //   number: "18746124516451986",
        //   date: "2020-12-12 14:30~15：30",
        //   model: "男上衣",
        //   type: "上衣",
        //   encipher: "B00101",
        //   size: "S",
        //   status: "未采集",
        // }
      ],
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 10,
        pageNo: 1,
        showSizeChanger: false, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      columns: [
        {
          title: "订单编号",
          dataIndex: "ordersn",
          // fixed: "left",
          width: 220,
        },
        { title: "商户名称", dataIndex: "member_name", width: 200 },
        {
          title: "采集时间",
          dataIndex: "date",
          width: 200,
        },
        {
          title: "采集机型",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "机器人名称",
          dataIndex: "type_name",
          width: 120,
        },
        {
          title: "编码",
          dataIndex: "code",
          width: 150,
        },
        {
          title: "规格",
          dataIndex: "size",
          width: 80,
        },
        {
          title: "状态",
          dataIndex: "statusName",
          fixed: "right",
          width: 100,
          filters: [
            {
              text: "未采集",
              value: "未采集",
            },
            {
              text: "采集中",
              value: "采集中",
            },
            {
              text: "已采集",
              value: "已采集",
            },
          ],
          // filterDropdownVisible:true,
          onFilter: (value, record) => record.statusName.indexOf(value) === 0,
        },
      ],
      type: 1,
      page: 1,
      limit: 15,
      statusNameObj: {
        0: "未采集",
        1: "采集中",
        2: "已采集",
      },
    };
  },
  created() {
    this.judge();
  },
  watch: {
    $route: function() {
      this.judge();
    },
  },
  methods: {
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireList(1);
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.aquireList(page);
    },
    judge() {
      let { id, type } = this.$route.query;
      type == "today"
        ? (this.title = "今日采集计划")
        : (this.title = "明日采集计划");
      this.id = id;
      type == "today" ? (this.type = 1) : (this.type = 2);
      this.pagination.pageNo = 1;
      this.aquireList();
    },
    selectSum(index) {
      this.select == index ? (this.select = -1) : (this.select = index);
    },
    aquireList() {
      collectList({
        type: this.type,
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
      })
        .then((res) => {
          // console.log(res, res.msg == "操作成功");
          res.data.list.forEach((item, index) => {
            item.key = index;
            item.statusName = this.statusNameObj[item.status];
          });
          this.dataSource = [];
          this.dataSource.push(...res.data.list);
          this.pagination.total = res.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 250px;
}
.client_input_box {
  width: 180px;
}
.content_item_title {
  font-size: 16px;
  color: #000000;
}
.select_info {
  background: #afe8bc;
  .etc-gantanhao-copy-copy {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
  i {
    font-style: normal;
    padding: 0px 2px;
    color: @primary-color;
  }
  .delete {
    color: red;
    user-select: none;
  }
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.stop {
  .circle {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: @primary-color;
    border-radius: 50%;
  }
  tr td:last-child {
    display: flex;
    justify-content: flex-end;
    a:nth-child(1) {
      color: @primary-color;
    }
    a:nth-child(2) {
      color: red;
    }
  }
  th:last-child {
    text-align: right;
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }
}
</style>
